import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'phone'
})
export class PhonePipe implements PipeTransform {
    transform(value: string): string {
        // Check if the value is a valid phone number
        if (!value || typeof value !== 'string') {
            return value;
        }

        // Remove all non-digit characters from the phone number
        const phoneNumber = value.replace(/\D/g, '');

        // Format the phone number based on the desired format
        let formattedPhoneNumber = '';
        if (phoneNumber.length === 10) {
            formattedPhoneNumber = `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 6)}-${phoneNumber.slice(6)}`;
        } else if (phoneNumber.length === 11) {
            formattedPhoneNumber = `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(2, 7)}-${phoneNumber.slice(7)}`;
        } else {
            formattedPhoneNumber = phoneNumber;
        }

        return formattedPhoneNumber;
    }
}