import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmComponent } from './confirm/confirm.component';
import { MessageService } from './message.service';
import { ToastComponent } from './toast/toast.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [ToastComponent, ConfirmComponent],
  declarations: [ToastComponent, ConfirmComponent],
})
export class MessageModule {
  static forRoot(): ModuleWithProviders<MessageModule> {
    return {
      ngModule: MessageModule,
      providers: [MessageService],
    };
  }
}
