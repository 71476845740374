import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';

export interface MessageEvent {
  type: 'info' | 'success' | 'warning' | 'error' | 'question';
  data: Message;
}

export interface Message {
  message: string;
  status?: number;
  autoclose?: boolean;
}

export interface ConfirmButton {
  text: string;
  close?: boolean;
  type?: 'confirm' | 'cancel' | 'deny';
  handler?: () => void;
}

export interface ConfirmRadioButton {
  text: string;
  value: string;
}

export interface Confirm {
  header?: string;
  message: string;
  icon?: 'info' | 'success' | 'warning' | 'error' | 'question';
  buttons: ConfirmButton[];
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public readonly onConfirm: EventEmitter<Confirm>;

  public readonly onMessage: EventEmitter<MessageEvent>;

  public readonly onError: EventEmitter<HttpErrorResponse>;

  constructor() {
    this.onConfirm = new EventEmitter<Confirm>();
    this.onMessage = new EventEmitter<MessageEvent>();
    this.onError = new EventEmitter<HttpErrorResponse>();
  }

  public addMsgInfo(data: Message): void {
    this.onMessage.emit({ type: 'info', data });
  }

  public addMsgWarning(data: Message): void {
    this.onMessage.emit({ type: 'warning', data });
  }

  public addMsgSuccess(data: Message): void {
    this.onMessage.emit({ type: 'success', data });
  }

  public addMsgDanger(data: Message): void {
    this.onMessage.emit({ type: 'error', data });
  }

  public addConfirm(confirm: Confirm): void {
    this.onConfirm.emit(confirm);
  }
}
