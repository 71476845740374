import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { AppComponent } from 'src/app/app.component';

import { MessageEvent, MessageService } from '..';
import Swal from 'sweetalert2'

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AppComponent),
  }],
})
export class ToastComponent implements OnInit {
  public items: MessageEvent[];
  public messageOpen!: boolean;
  public message!: string;

  @ViewChild('alert') alert!: SwalComponent;

  constructor(private messageService: MessageService) {
    this.items = [];
    this.messageOpen = false;
  }

  ngOnInit(): void {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 5000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      }
    });

    this.messageService.onMessage.subscribe((event: MessageEvent) => {      
      const { data } = event;

      if (`${data.message ?? ''}`.length === 0 || this.messageOpen) {
        return;
      }
      this.messageOpen = true;

      this.items.push(event);
      
      if (this.items.length > 0) {
        this.items.forEach((item, index) => {
          setTimeout(() => {
            Toast.fire({
              icon: item.type,
              title: item.data.message
            });
            this.items.splice(index, 1)
          }, index * 3000);
        });
      }

      this.messageOpen = false;
    });
    
  }

}
