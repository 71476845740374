import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Message, MessageService } from 'src/app/shared/message';
import { AuthenticationService } from '../service/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor
    (
      private messageService: MessageService,
      private authenticationService: AuthenticationService,
      private router: Router
    ) { }

  public handleError(error: HttpErrorResponse, showMessage?: boolean): Observable<never> {
    if (showMessage) {
      this.handleServiceError(error.status, error?.error?.message); // Handle specific HTTP status codes
    }
    // Display the error message to the user
    console.error(error); // Log error message to the console

    return throwError(() => error);
  }

  private handleServiceError(status: number, errorMessage?: string): void {
    switch (status) {
      case 400:
        // Bad Request        
        this.messageService.addMsgWarning({ message: errorMessage ?? 'Requisição inválida. Verifique os dados fornecidos.' });
        break;
      case 401:
        // Unmemberized
        this.messageService.addMsgWarning({ message: errorMessage ?? 'Você não está autorizado a acessar este recurso.' });
        break;
      case 403:
        // Forbidden
        this.messageService.addMsgWarning({ message: 'Sua sessão expirou. Faça login novamente.' });
        this.authenticationService.logout();
        this.router.navigate(['/auth/login']);
        break;
      case 404:
        // Not Found
        this.messageService.addMsgWarning({ message: 'O recurso solicitado não foi encontrado.' });
        break;
      case 500:
        // Internal Server Error
        this.messageService.addMsgDanger({ message: 'Ocorreu um erro interno no servidor. Tente novamente mais tarde.' });
        break;
      default:
        this.messageService.addMsgDanger({ message: 'Ocorreu um erro inesperado.' });
        break;
    }
  }
}
