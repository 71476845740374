import { Injectable, Inject, InjectionToken } from '@angular/core';

import { environment } from 'src/environments/environment';
import { ApplicationScheme, EnvironmentScheme } from 'src/environments/scheme';

export interface EnvironmentConfig {
  application: ApplicationScheme;
  environment: EnvironmentScheme;
}

export const ENV_CONFIG = new InjectionToken<EnvironmentConfig>('ENV_CONFIG');

@Injectable()
export class Environment {
  constructor(@Inject(ENV_CONFIG) private readonly config: EnvironmentConfig) {}

  public get production(): boolean {
    return environment.production;
  }

  public get application(): ApplicationScheme {
    return this.config.application;
  }

  public get environment(): EnvironmentScheme {
    return this.config.environment;
  }

  public get api(): ApplicationScheme {
    return this.config.environment.api;
  }
}
