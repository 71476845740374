import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService } from '../service/auth.service';
import { NO_AUTH } from './no-auth.inteceptor';
import { ErrorHandlerService } from './error-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
        private errorHandlerService: ErrorHandlerService

    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let showMessage = true
        if (request.context.get(NO_AUTH)) {
            return next.handle(request);
          }
          
          if(request.params.get('showMessage') === 'false'){
            showMessage = false
          }
          return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => this.errorHandlerService.handleError(error, showMessage))
          );
    }
}
