import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private stop: number;

  public readonly onStart: EventEmitter<void>;

  public readonly onStop: EventEmitter<void>;

  constructor() {
    this.stop = 0;
    this.onStop = new EventEmitter<void>();
    this.onStart = new EventEmitter<void>();
  }

  public enable(): void {
    --this.stop;
  }

  public disable(): void {
    ++this.stop;
  }

  public get active(): boolean {
    return this.stop === 0;
  }
}
