import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment } from 'src/libs/env/environment';
import { NO_AUTH } from './no-auth.inteceptor';

@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private environment: Environment) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (request.context.get(NO_AUTH)) {
      return next.handle(request);
    }

    const updateReq = request.clone({
      headers: request.headers.append('X-Tenant-ID', this.environment.api.tenant),
    });
    return next.handle(updateReq);
  }
}
