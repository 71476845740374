import { EnvironmentScheme } from './scheme';

export const environment: EnvironmentScheme = {
  production: false,
  api: {
    webURL: 'http://localhost:4200',
    apiURL: '',
    admApiURL: '',
    socioApiURL: '',
    tenant:'__TENANT_ID__',
    urlS3: '__S3__'
  },
  GOOGLE_MAPS_API_KEY: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4'
};
