import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ENV_CONFIG, Environment, EnvironmentConfig } from './environment';

@NgModule({
  imports: [CommonModule],
})
export class EnvModule {
  public static forRoot(config: EnvironmentConfig): ModuleWithProviders<EnvModule> {
    return {
      ngModule: EnvModule,
      providers: [
        Environment,
        {
          provide: ENV_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
