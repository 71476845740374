import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from 'src/libs/env/environment';
import { Page } from 'src/app/shared/pagination';
import { getMapParamsPage } from 'src/app/core/helpers/utils';
import { GeneralConfigModel } from './models/general-config.model';

@Injectable({
    providedIn: 'root'
})
export class GeneralConfigService {
    constructor(private http: HttpClient, private environment: Environment) { }

    public save(data: GeneralConfigModel): Observable<GeneralConfigModel> {
        return this.http.post<GeneralConfigModel>(`${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal`, {
            ...data
        });
    }

    public edit(id: string, data: GeneralConfigModel): Observable<GeneralConfigModel> {
        return this.http.put<GeneralConfigModel>(`${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal/${id}`, {
            ...data
        });
    }

    public updateStatus(id: string, isActive: boolean): Observable<GeneralConfigModel> {
        return this.http.patch<GeneralConfigModel>(`${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal/${id}/toggle-active-deactive/${isActive}`, null);
    }

    public delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal/${id}`);
    }

    public changeStatus(id: string, isActive: boolean): Observable<void> {
        return this.http.patch<void>(
            `${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal/${id}/toggle-active-deactive/${isActive}`,
            {}
        );
    }

    public getAll(filter?: any): Observable<Page<GeneralConfigModel>> {
        let params = new HttpParams();

        if (filter && filter.pageNumber) {
            params = params.append('pageNumber', filter.pageNumber);
        }

        if (filter && filter.pageSize) {
            params = params.append('pageSize', filter.pageSize);
        }

        return this.http.get<Page<GeneralConfigModel>>(
            `${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal`,
            { params }
        );
    }

    public getAllPublic(filter?: any): Observable<Page<GeneralConfigModel>> {
        let params = new HttpParams();

        if (filter && filter.pageNumber) {
            params = params.append('pageNumber', filter.pageNumber);
        }

        if (filter && filter.pageSize) {
            params = params.append('pageSize', filter.pageSize);
        }

        return this.http.get<Page<GeneralConfigModel>>(
            `${this.environment.api.socioApiURL}/public/api/v1/socio/general-configuration-portal`,
            { params }
        );
    }

    public getById(id: string): Observable<GeneralConfigModel> {
        return this.http.get<GeneralConfigModel>(`${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal/${id}`);
    }

    public search(params?: HttpParams): Observable<Page<GeneralConfigModel>> {
        return this.http.get<Page<GeneralConfigModel>>(
            `${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal/search`,
            { params }
        );
    }

    public getGeneralConfigModels(): Observable<GeneralConfigModel[]> {
        const params = getMapParamsPage({ isActive: true }, 999, 0)
        return this.http.get<GeneralConfigModel[]>(
            `${this.environment.api.socioApiURL}/api/v1/socio/general-configuration-portal/active/true`,
            { params }
        );
    }
}
