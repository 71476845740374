import { Component } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  public show: boolean;

  constructor(private loaderService: LoaderService) {
    this.show = false;

    this.loaderService.onStart.subscribe(() => {
      setTimeout(() => {
        this.show = true;
      });
    });

    this.loaderService.onStop.subscribe(() => {
      setTimeout(() => {
        this.show = false;
      });
    });
  }
}
