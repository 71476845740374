import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private count: number;

  constructor(private loaderService: LoaderService) {
    this.count = 0;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(request.params.get('showLoader') === 'false'){
      return next.handle(request);      
    }
    const active = this.loaderService.active;
    
    

    if (++this.count > 0 && active) {
      this.loaderService.onStart.emit();
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (--this.count < 1) {
          this.loaderService.onStop.emit();
        }
      })
    );
  }
}
