import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { LoaderComponent } from './loader.component';
import { LoaderInterceptor } from './loader.interceptor';
import { LoaderService } from './loader.service';

@NgModule({
  imports: [CommonModule],
  providers: [LoaderService],
  exports: [LoaderComponent],
  declarations: [LoaderComponent],
})
export class LoaderModule {
  static forRoot(): ModuleWithProviders<LoaderModule> {
    return {
      ngModule: LoaderModule,
      providers: [
        LoaderService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true,
        },
      ],
    };
  }
}
