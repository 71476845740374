import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { User } from './core/models/auth.models';
import { AuthenticationService, RefreshEvent } from './core/service/auth.service';
import { LoaderService } from './shared/loader';
import { GeneralConfigService } from './libs/services/general-config/general-config.service';
import { GeneralConfigModel } from './libs/services/general-config/models/general-config.model';
import { FileService } from './libs/services/file/file.service';
import { FaviconService } from './libs/services/favicon/favicon.service';
import { LoginService } from './libs/services/login/login.service';
import { LoginModel } from './libs/services/login/models/login.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  generalConfig: GeneralConfigModel;
  loginConfig: LoginModel;

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private loaderService: LoaderService,
    private generalConfigService: GeneralConfigService,
    private fileService: FileService,
    private faviconService: FaviconService,
    private loginConfigService: LoginService
    ) {
    this.initSecurity();
    this.getGeneralConfig();
    this.getLoginConfig();
  }

  private initSecurity(): void {
    // this.securityService.onInit.subscribe(() => this.stateService.deleteAll());

    this.authService.onUnauthorized.subscribe(() => {
      this.authService.invalidate();
    });

    this.authService.onForbidden.subscribe(() => {
      this.router.navigate(['/error/403']);
    });

    this.authService.onInvalidate.subscribe(() => {
      this.router.navigate(['/auth/login']);
    });

    this.authService.onRefresh.subscribe((event: RefreshEvent) => {
      const { refreshToken = '' } = event.credential || {};
      this.loaderService.disable();

      this.authService.refresh(refreshToken).subscribe({
        next: (credential: User) => {
          this.authService.setRoles(credential.roles);
          event.next(credential);
          this.loaderService.enable();
        },
        error: (error: any) => {
          event.error(error);
          this.loaderService.enable();
        },
      });
    });

    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationStart && !event.url.startsWith('/access/refresh') && !this.authService.isIni))
      .subscribe(() => this.authService.init());
  }

  getGeneralConfig() {
    this.generalConfigService.getAllPublic().subscribe((res: any) => {
      this.generalConfig = res.content[0];
      let title = document.getElementById('siteTitle');
      if (title) {
        title.innerText = this.generalConfig.pageTitle;
      }
      localStorage.setItem('imageLogo', this.fileService.getUrlSocio(this.generalConfig.imageLogo.id as string));
      localStorage.setItem('clubName', this.generalConfig.name as string);
      this.faviconService.setFavicon(localStorage.getItem('imageLogo') || 'favicon.ico');
    });

  }

  getLoginConfig() {
    this.loginConfigService.getAllPublic().subscribe((res: any) => {
      this.loginConfig = res.content[0];
  
      if (this.loginConfig.isImage) {
        const backgroundImageUrl = this.fileService.getUrlSocio(this.loginConfig.backgroundImage.id as string);
        localStorage.setItem('backgroundImage', backgroundImageUrl);
        
        document.documentElement.style.setProperty('--bg-image', `url(${backgroundImageUrl})`);
      } else {
        document.documentElement.style.setProperty('--bg-color', this.loginConfig.backgroundColor);
      }
    });
  }
}
