import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from 'src/libs/env/environment';
import { Page } from 'src/app/shared/pagination';
import { getMapParamsPage } from 'src/app/core/helpers/utils';
import { LoginModel } from './models/login.model';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(private http: HttpClient, private environment: Environment) {}

    public getById(id: string): Observable<LoginModel> {
        return this.http.get<LoginModel>(`${this.environment.api.socioApiURL}/api/v1/socio/login-config/${id}`);
    }    
    
    public save(data: LoginModel): Observable<LoginModel> {
        return this.http.post<LoginModel>(`${this.environment.api.socioApiURL}/api/v1/socio/login-config`, {
            ...data
        });
    }

    public edit(id: string, data: LoginModel): Observable<LoginModel> {
        return this.http.put<LoginModel>(`${this.environment.api.socioApiURL}/api/v1/socio/login-config/${id}`, {
            ...data
        });
    }

    
    public getAll(filter?: any): Observable<Page<LoginModel>> {
        let params = new HttpParams();

        if (filter && filter.pageNumber) {
            params = params.append('pageNumber', filter.pageNumber);
        }

        if (filter && filter.pageSize) {
            params = params.append('pageSize', filter.pageSize);
        }

        return this.http.get<Page<LoginModel>>(
            `${this.environment.api.socioApiURL}/api/v1/socio/login-config`,
            { params }
        );
    }

    public getAllPublic(filter?: any): Observable<Page<LoginModel>> {
        let params = new HttpParams();

        if (filter && filter.pageNumber) {
            params = params.append('pageNumber', filter.pageNumber);
        }

        if (filter && filter.pageSize) {
            params = params.append('pageSize', filter.pageSize);
        }

        return this.http.get<Page<LoginModel>>(
            `${this.environment.api.socioApiURL}/public/api/v1/socio/login-config`,
            { params }
        );
    }

    public removeImageLogoByNextId(loginConfigId: string, data: any): Observable<void> {
        return this.http.delete<void>(`${this.environment.api.socioApiURL}/api/v1/socio/login-config/${loginConfigId}/image-logo`);
      }
}
