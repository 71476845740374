import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { FakeBackendProvider } from './helpers/fake-backend';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { CpfPipe } from './pipes/cpf.pipe';
import { CpfDirective } from './directives/cpf.directive';
import { MoneyDirective } from './directives/money.directive';
import { CnpjPipe } from './pipes/cnpj.pipe';
import { TenantInterceptor } from './helpers/tenant.interceptor';
import { PhoneDirective } from './directives/phone.directive';
import { CpfCnpjDirective } from './directives/cpfcnpj.directive';
import { PhonePipe } from './pipes/phone.pipe';
import { CnpjDirective } from './directives/cnpj.directive';

let components = [
  CpfPipe,
  CpfDirective,
  MoneyDirective,
  CnpjPipe,
  PhoneDirective,
  PhonePipe,
  CpfCnpjDirective,
  CnpjDirective
];


@NgModule({
  declarations: components,
  imports: [CommonModule],
  exports: components,
  providers: [
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TenantInterceptor, multi: true },
    // provider used to create fake backend
    // FakeBackendProvider,
  ],
})
export class CoreModule { }
