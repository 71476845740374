import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from 'src/libs/env/environment';
import { Page } from 'src/app/shared/pagination';
import { DocumentFile } from './model/document.model';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private environment: Environment) {}

  public download(id: string): Observable<DocumentFile> {
    return this.http.get<DocumentFile>(
      `${this.environment.api.urlS3}/${id}`

    );
  }

  public downloadBase64(url): Observable<DocumentFile> {
    return this.http.get<DocumentFile>(`${url}`);
  }

  public getUrlAdmin(id: string): string {
    return (
      `${this.environment.api.urlS3}/${id}`
    );
  }

  public getUrlSocio(id: string): string {
    return (
      `${this.environment.api.urlS3}/${id}`
    );
  }
}
