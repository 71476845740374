import {
  Directive,
  ElementRef,
  HostListener,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
} from '@angular/forms';
import * as StringMask from 'string-mask';

@Directive({
  selector: '[CnpjMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CnpjDirective),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CnpjDirective),
      multi: true,
    },
  ],
})
export class CnpjDirective implements OnInit, ControlValueAccessor {
  private cnpjPattern = new StringMask('00.000.000/0000-00');

  private onChangeCallback = (_: any) => {};
  private onTouchCallback = () => {};

  constructor(private _elementRef: ElementRef) {}

  ngOnInit() {
    const cleanValue: string = this._cleanValue(
      this._elementRef.nativeElement.value
    );
    this._applyValueChanges(cleanValue);
  }

  @HostListener('input')
  onInput(): void {
    const cleanValue: string = this._cleanValue(
      this._elementRef.nativeElement.value
    );
    this._applyValueChanges(cleanValue);
  }

  public writeValue(inputValue: string): void {
    if (!inputValue) {
      return;
    }

    const cleanValue: string = this._cleanValue(inputValue);
    this._elementRef.nativeElement.value = this.cnpjPattern.apply(cleanValue);
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  private _applyValueChanges(cleanValue: string): void {
    let formattedValue = this.cnpjPattern.apply(cleanValue);
    formattedValue = formattedValue.trim().replace(/[^0-9]$/, '');
    this._elementRef.nativeElement.value = formattedValue;
    this.onChangeCallback(cleanValue);
  }

  private _cleanValue(viewValue: string): string {
    return viewValue.replace(/[^\d]/g, '').slice(0, 14);
  }
}
