import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { JoyrideModule } from 'ngx-joyride';
import { MessageModule } from './shared/message';
import { EnvModule } from 'src/libs/env/env.module';
import { environment } from 'src/environments/environment';
import { LoaderModule } from './shared/loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { APP_BASE_HREF } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask'

import { registerLocaleData } from '@angular/common';
import localePT from '@angular/common/locales/pt';
registerLocaleData(localePT);

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    MessageModule.forRoot(),
    JoyrideModule.forRoot(),
    SweetAlert2Module.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CoreModule,
    LayoutModule,
    AppRoutingModule,
    EnvModule.forRoot({
      environment,
      application: environment.api,
    }),
    NgxMaskModule.forRoot(),
    LoaderModule.forRoot()
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    }

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
