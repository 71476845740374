import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import Swal from 'sweetalert2';
import { Confirm, ConfirmButton, MessageService } from '..';


@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmComponent implements OnInit {
  public confirm?: Confirm;

  constructor(private messageService: MessageService) { }

  ngOnInit(): void {
    this.messageService.onConfirm.subscribe((confirm: Confirm) => {
      this.confirm = confirm;

      if (`${confirm.message ?? ''}`.length === 0) {
        return;
      }

      let config = Object.assign({});
      confirm.buttons.forEach(button => {
        if (button.type === 'deny') {
          config.showDenyButton = true;
          config.denyButtonText = button.text;
        }
        if (button.type === 'confirm') {
          config.showConfirmButton = true;
          config.confirmButtonText = button.text;
        }
        if (button.type === 'cancel') {
          config.showCancelButton = true;
          config.cancelButtonText = button.text;
        }
      });

      const Confirm = Swal.mixin({
        ...config,
        customClass: {
          denyButton: 'swal2-deny-custom',
        },
        icon: confirm.icon || '',
        html: confirm.message,
      });

      if (Confirm) {
        Confirm.fire().then((result) => {
          if (result.isConfirmed) {
            let button = confirm.buttons.filter(button => button.type === 'confirm')[0];
            if (button && button.handler) {
              button.handler();
            }
          } else if (result.isDismissed) {
            let button = confirm.buttons.filter(button => button.type === 'cancel')[0];
            if (button && button.handler) {
              button.handler();
            }
          } else if (result.isDenied) {
            let button = confirm.buttons.filter(button => button.type === 'deny')[0];
            if (button && button.handler) {
              button.handler();
            }
          }
        });
      }

    });
  }

  public get actions(): ConfirmButton[] {
    return this.confirm?.buttons || [];
  }
}
