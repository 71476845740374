import { HttpParams } from "@angular/common/http";
import { User } from "../models/auth.models";

/**
 * @returns registered user list
 */
function getAllUsers(): User[] {
  // array in session storage for registered users
  let users: User[] = JSON.parse(localStorage.getItem('users')!) || [
    {
      id: 1, username: 'test', email: 'adminto@coderthemes.com', password: 'test', firstName: 'Nowak', lastName: 'Helme',
      avatar: 'assets/images/users/user-1.jpg', location: 'California, USA', title: 'Admin Head'
    }
  ];
  return users;
}


/**
 * Returns the current user
 */
function loggedInUser(): User | null {
  let user: User | null = {};
  user = JSON.parse(localStorage.getItem('currentUser')!);
  return user;
}

function validateCpf(cpf: number | string) {
  let sum = 0;
  let rest;

  let strCPF = String(cpf).replace(/[^\d]/g, '');

  if (strCPF.length !== 11) {
    return false;
  }

  if ([
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ].indexOf(strCPF) !== -1) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  }
  rest = (sum * 10) % 11;

  if ((rest == 10) || (rest == 11)) {
    rest = 0;
  }

  if (rest != parseInt(strCPF.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  }
  rest = (sum * 10) % 11;

  if ((rest == 10) || (rest == 11)) {
    rest = 0;
  }

  if (rest != parseInt(strCPF.substring(10, 11))) {
    return false;
  }

  return true;
}

function get_http_params_from_object(obj: any): HttpParams {
  let params = new HttpParams();
  Object.keys(obj).forEach((key: any, index: number) => {
    if (obj[key] !== null && obj[key] !== '' && obj[key] !== undefined) {
      params = params.append(key, obj[key].toString());
    }
  });
  return params;
}

function getMapParamsPage(filter: any, size?: number, page?: number): HttpParams {
  let params = get_http_params_from_object(filter);
  if (page != null) {
    params = params.append('pageNumber', page);
  }
  if (size != null) {
    params = params.append('pageSize', size);
  }
  return params;
}

export { getAllUsers, loggedInUser, validateCpf, getMapParamsPage }

