import { Directive, ElementRef, HostListener, OnInit, forwardRef } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor, Validators } from '@angular/forms';
import { validateCpf } from '../helpers/utils';

export function ValidateCpf() {
  return (c: FormControl): Validators => {
    const err = {
      cpfPattern: true
    };
    return c.value && !validateCpf(c.value) ? err : null;
  };
}

@Directive({
  selector: '[cpfMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CpfDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CpfDirective),
      multi: true
    }
  ]
})
export class CpfDirective implements OnInit, ControlValueAccessor {

  public onChangeCallback = (_: any) => { };
  @HostListener('blur', ['$event'])
  public onTouchCallback = () => { };
  validateFn: any = () => { };

  constructor(private _elementRef: ElementRef) { }

  ngOnInit() {
    const cleanValue: string = this._cleanValue(this._elementRef.nativeElement.value);
    this._applyValueChanges(cleanValue);
  }

  @HostListener('input')
  onKeydow(): void {
    const cleanValue: string = this._cleanValue(this._elementRef.nativeElement.value);
    this._applyValueChanges(cleanValue);
  }

  public writeValue(modelValue: string): void {
    if (modelValue === null || modelValue === undefined) {
      this._elementRef.nativeElement.value = '';
      return;
    }

    const cleanValue: string = this._cleanValue(modelValue);
    this._applyValueChanges(cleanValue);
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  public _applyValueChanges(cleanValue: string): void {
    let value = cleanValue.trim().replace(/[^0-9]$/, '');
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    this._elementRef.nativeElement.value = value;
    this.onChangeCallback(cleanValue);
  }

  public _cleanValue(viewValue: string): string {
    return viewValue.replace(/[^\d]/g, '').slice(0, 11);
  }

  validate(c: FormControl) {
    if (c.value) {
      this.validateFn = ValidateCpf();
    }
    return this.validateFn(c);
  }

}
