import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'cnpj'
  })
  export class CnpjPipe implements PipeTransform {
  
    transform(value: string): string {
      let formatedValue = value + '';
  
      return formatedValue
      .replace(/[^0-9]/g, '')
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }
  }